import React, { Component } from 'react';
import { HOUSES } from '../../data/houses';
import { Link } from 'react-router-dom';
import { getFloorDescription } from '../../utils/houses';
import * as queryString from 'querystring';

class HousesGrid extends Component {
  render() {
    const gridElements = Object.keys(HOUSES.housesDB);
    const gridWidth = gridElements.length > 2 ? 100 : 70;
    return (
      <div className="houses-grid h-houses-grid w-full lg:pin-b lg:absolute lg:mb-20px min-h-fit-content ">
        <div
          className="flex flex-col lg:flex-row lg:flex-wrap justify-around items-center p-5 m-auto"
          style={{ width: `${gridWidth}%` }}
        >
          {this.renderHousesGrid()}
        </div>
      </div>
    );
  }

  renderHousesGrid = () => {
    const housesDB = HOUSES.housesDB;
    return Object.keys(housesDB).map(lot => {
      return (
        <div key={lot} className={`${lot} w-lot z-10 relative lot`}>
          {this.renderLot(lot)}
        </div>
      );
    });
  };

  renderLot = lotId => {
    const lot = HOUSES.housesDB[lotId];
    const available = !!lot.available;
    return (
      <div>
        {!available && (
          <div class="available-soon-mark w-lot">
            <h3>{lot.availableText}</h3>
          </div>
        )}
        <div className="lot-title bg-theme-primary p-2 text-white text-center font-bold mt-1 md:mt-0">
          {lot.title}
        </div>
        <div className="flex flex-row justify-around items-center">
          {Object.keys(lot.types).map(type => {
            return (
              <div key={type} className="mt-4 text-center">
                <h3 className="text-black md:text-white">{type}</h3>
                {this.renderHouses(
                  lotId,
                  type,
                  lot.types[type],
                  lot.title,
                  available,
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderHouses = (lot, type, houses, lotTitle, available) => {
    return houses
      .sort((house1, house2) => house2.floor - house1.floor)
      .map((house, index) => {
        return (
          <div
            key={`house-${index}`}
            className={`house-btn h-12 p-2 text-center mb-2 w-house-btn text-theme-text ${
              house.sold
                ? 'bg-theme-house-btn-sold font-bold sold'
                : 'bg-theme-house-btn'
            }`}
          >
            {house.sold && <span>vendido</span>}
            {!house.sold && !available && (
              <div className="flex flex-col cursor-pointer text-theme-text ">
                <span className="font-bold">
                  {getFloorDescription(house.floor)}
                </span>
                <span className="text-xs font-light">{house.side}</span>
              </div>
            )}
            {!house.sold && available && (
              <Link
                className="flex flex-col cursor-pointer text-theme-text "
                to={{
                  pathname: '/house-detail',
                  search: queryString.stringify({
                    lot,
                    lotTitle,
                    type,
                    houseIndex: index,
                  }),
                }}
              >
                <span className="font-bold">
                  {getFloorDescription(house.floor)}
                </span>
                <span className="text-xs font-light">{house.side}</span>
              </Link>
            )}
          </div>
        );
      });
  };
}

export default HousesGrid;
