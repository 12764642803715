export const INFO = {
  title: 'Urbanização Quinta do Inquisidor',
  description:
    'Urbanização quinta do inquisidor - Apartamentos novos T2 e T3 para venda em Loures. Casas para venda em Loures.',
  mainAction: 'ver apartamentos',
  logoHome: 'img/logo-home.png',
  logoMenu: 'img/logo-menu.png',
  logoConstrucoes: 'img/logo-construcoes.png',
  footerMsg: 'Todos os direitos reservados Construções Inocêncio & Filhos Lda.',
  notFoundMessage:
    'A página que tentou aceder não existe ou a sua localização mudou!',
  notFoundHomeLink: 'aceder à página inicial',
  comingSoonImage: 'img/home/home-1.jpg',
  comingSoonMessage: 'Brevemente disponível',
  comingSoonFlag: true,
  organizationEmail: 'inocencio.lda@sapo.pt',
  organizationPhone1: '219 824 082',
  organizationPhone2: '963 375 365',
  personalEmail: 'inocencio3@hotmail.com',
  address1: 'Rua da Mata, Lote 3',
  address2: 'Quinta do Inquisidor, Barro - Loures',
  videoId: `${process.env.REACT_APP_BASE_URL}/video/video-quinta-inquisidor.mp4`,
  pdfUrl: 'Quinta do Inquisidor_ACABAMENTOS.pdf',
};
