const blueprintsIds = [
  'T2_1dto',
  'T2_2dto',
  'T2_3dto',
  'T2_4dto',
  'T3_1esq',
  'T3_2esq',
  'T3_3esq',
  'T3_4esq',
  'L4_T3_1esq',
  'L4_T3_3esq',
];
let blueprints = {};
blueprintsIds.forEach(id => {
  blueprints[id] = {
    blueprints: [`img/houses/blueprint/${id}.jpg`],
    blueprintsMob: [
      `img/houses/blueprint/${id}-mob.jpg`,
      `img/houses/blueprint/${id}-box-mob.jpg`,
    ],
  };
});
const slideSize = 6;
const detailSlider = [];
for (let i = 1; i <= slideSize; i++) {
  detailSlider.push({
    original: `img/houses/slider/inside${i}.jpg`,
    thumbnail: `img/houses/slider/inside${i}.jpg`,
  });
}
const mezineImages = [];
const numberMezImages = 10;
for (let i = 1; i <= numberMezImages; i++) {
  mezineImages.push({
    original: `img/houses/slider/mez${i}.jpg`,
    thumbnail: `img/houses/slider/mez${i}.jpg`,
  });
}
const slideSize_L1_3 = 5;
const detailSliderL1_3 = [];
for (let i = 1; i <= slideSize_L1_3; i++) {
  detailSliderL1_3.push({
    original: `img/houses/slider/L1_3_inside${i}.jpg`,
    thumbnail: `img/houses/slider/L1_3_inside${i}.jpg`,
  });
}

export const HOUSES_IMAGES = detailSliderL1_3;

const housesDB = {
  lot1: {
    title: 'Lote 1',
    available: true,
    availableText: 'Brevemente',
    types: {
      T2: [
        {
          floor: 1,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_1dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 2,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_2dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 3,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_3dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 4,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_4dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
      ],
      T3: [
        {
          floor: 1,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_1esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 2,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_2esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 3,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_3esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 4,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_4esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
      ],
    },
  },
  lot2: {
    title: 'Lote 2',
    available: true,
    availableText: 'Brevemente',
    types: {
      T2: [
        {
          floor: 1,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_1dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 2,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_2dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 3,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_3dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 4,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_4dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
      ],
      T3: [
        {
          floor: 1,
          side: 'fração esquerda',
          sold: false,
          ...blueprints['T3_1esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 2,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_2esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 3,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_3esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 4,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_4esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
      ],
    },
  },
  lot3: {
    title: 'Lote 3',
    available: true,
    availableText: 'Vendas Brevemente',
    types: {
      T2: [
        {
          floor: 1,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_1dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 2,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_2dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 3,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_3dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 4,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_4dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: detailSliderL1_3,
        },
      ],
      T3: [
        {
          floor: 1,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_1esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 2,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_2esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 3,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_3esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
        {
          floor: 4,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_4esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: detailSliderL1_3,
        },
      ],
    },
  },
  lot4: {
    title: 'Lote 4',
    available: true,
    types: {
      T2: [
        {
          floor: 1,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_1dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider,
        },
        {
          floor: 2,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_2dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider,
        },
        {
          floor: 3,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_3dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider,
        },
        {
          floor: 4,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_4dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: [...detailSlider, ...mezineImages],
        },
      ],
      T3: [
        {
          floor: 1,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['L4_T3_1esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider,
        },
        {
          floor: 2,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_2esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider,
        },
        {
          floor: 3,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['L4_T3_3esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider,
        },
        {
          floor: 4,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_4esq'],
          typology: 'T3',
          virtualVisit: 'https://tourwizard.net/2n2t3loures/',
          detailSlider: [...detailSlider, ...mezineImages],
        },
      ],
    },
  },
  lot5: {
    title: 'Lote 5',
    available: true,
    types: {
      T2: [
        {
          floor: 1,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_1dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider,
        },
        {
          floor: 2,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_2dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider,
        },
        {
          floor: 3,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_3dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider,
        },
        {
          floor: 4,
          side: 'fração direita',
          sold: true,
          ...blueprints['T2_4dto'],
          typology: 'T2',
          virtualVisit: 'https://tourwizard.net/2n2t2loures/',
          detailSlider: [...detailSlider, ...mezineImages],
        },
      ],
      T3: [
        {
          floor: 1,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_1esq'],
          typology: 'T3',
          detailSlider,
        },
        {
          floor: 2,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_2esq'],
          typology: 'T3',
          detailSlider,
        },
        {
          floor: 3,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_3esq'],
          typology: 'T3',
          detailSlider,
        },
        {
          floor: 4,
          side: 'fração esquerda',
          sold: true,
          ...blueprints['T3_4esq'],
          typology: 'T3',
          detailSlider: [...detailSlider, ...mezineImages],
        },
      ],
    },
  },
};

export const HOUSES = {
  mainImage: {
    path: 'img/houses/main.jpg',
    description: 'Seleccionar apartamento',
  },
  housesDB,
};
