import { INFO } from '../data/general-info';

export const mailSender = {
  url: 'https://api.smtp2go.com/v3/email/send',
  sender: `Quinta do Inquisidor <admin@quintadoinquisidor.pt>`,
  to: [INFO.organizationEmail],
  bcc: [INFO.personalEmail],
  apiKey: 'api-446E1588427811E9A6BEF23C91BBF4A0',
  subjectBooking: 'Quinta do Inquisidor - Marcação ',
  subjectContact: 'Quinta do Inquisidor - Informações ',
  bookKey: 'book',
  infoKey: 'info',
};
