import React, { Component, Fragment } from 'react';
import Navigator from '../../components/navigator/navigator.component';
import { Link } from 'react-router-dom';
import Constants from '../../data/map-info';
import GoogleMapReact from 'google-map-react';
import Footer from '../../components/footer/footer.component';

let directionsDisplay = null;
let directionsService = null;

class Location extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainMarker: {
        ...Constants.mapCenter,
        icon: (
          <img
            src="img/icones/location-marker.png"
            alt="main-marker"
            className="h-12"
          />
        ),
      },
      additionalMarkers: [],
    };
  }

  renderTitle = () => (
    <span>
      <h2 className="lg:text-3xl sm:lg">localização</h2>
      <h1 className="lg:text-5xl sm:xl uppercase font-semibold mt-0 mb-5">
        Proximidades
      </h1>
      <Link to="houses" className="btn mt-5 btn-menu">
        ver apartamentos
      </Link>
    </span>
  );

  renderTitleMobile = () => (
    <span className="block lg:hidden w-5/6 m-auto min-h-fit-content mt-6">
      {this.renderTitle()}
    </span>
  );

  renderTitleDesktop = () => (
    <span className="hidden lg:block title-desktop absolute z-10 lg:flex flex-col sm:pt-10 sm: mt-10 lg:mt-72 text-white ml-menu-title lg:h-64 lg:w-1/3">
      {this.renderTitle()}
    </span>
  );

  renderAdditionalMarkers() {
    const { additionalMarkers } = this.state;

    return additionalMarkers.map(marker => {
      return (
        <Fragment lat={marker.lat} lng={marker.lng}>
          {marker.icon}
        </Fragment>
      );
    });
  }

  renderMainMarker() {
    const { mainMarker, map, maps } = this.state;

    if (!map || !maps) return;

    new maps.Marker({
      position: mainMarker,
      map: map,
      icon: {
        url: 'img/icones/location-marker.png',
      },
    }).addListener('click', () =>
      window.open(
        `https://maps.google.com/?q=${mainMarker.lat},${mainMarker.lng}`,
        '_blank',
      ),
    );
  }

  renderDirections(map, maps, selectedLocation) {
    if (!directionsDisplay) {
      directionsService = new maps.DirectionsService();
      directionsDisplay = new maps.DirectionsRenderer();
    }

    directionsDisplay.setMap(map);
    directionsDisplay.setOptions({
      polylineOptions: {
        strokeWeight: 6,
        strokeOpacity: 1,
        strokeColor: '#f5ca3f',
      },
      suppressMarkers: true,
    });

    let request = {
      origin: '38.836785,-9.191209',
      destination: selectedLocation,
      travelMode: 'DRIVING',
    };
    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        directionsDisplay.setDirections({ routes: [] });
        directionsDisplay.setDirections(result);
      }
    });
  }

  render() {
    return (
      <div className="lg:h-full lg:h-full w-full flex lg:flex-row">
        <Navigator {...this.props} />
        {this.renderTitleDesktop()}
        <div className="w-full h-full lg:overflow-auto lg:mt-0">
          <div className="lg:h-body-container">
            <div className="w-full h-map">
              <GoogleMapReact
                bootstrapURLKeys={{ key: Constants.apiKey }}
                defaultZoom={18}
                center={Constants.mapCenter}
                options={Constants.options}
                zoom={18}
                onGoogleApiLoaded={({ map, maps }) => {
                  this.setState({ map, maps });
                }}
                yesIWantToUseGoogleMapApiInternals={true}
              >
                {this.renderMainMarker()}
                {this.renderAdditionalMarkers()}
              </GoogleMapReact>
            </div>
            {this.renderTitleMobile()}
            <div className="services lg:flex justify-around mt-16 lg:mt-3">
              <div className="flex flex-col flex-start justify-around h-48">
                <div className="flex w-4/5 lg:w-auto m-auto min-h-fit-content">
                  <img
                    src="img/icones/i-plane.png"
                    alt="aeroporto"
                    className="h-6"
                  />
                  <div className="ml-4">
                    <span className="font-semibold">Aeroporto</span>
                    <br />
                    15 km | 20 min
                  </div>
                </div>
                <div className="flex w-4/5 lg:w-auto m-auto min-h-fit-content">
                  <img
                    src="img/icones/i-pharmacy.png"
                    alt="farmácia"
                    className="h-6"
                  />
                  <div className="ml-4">
                    <span className="font-semibold">Farmácia</span>
                    <br />
                    1,2 km | 3 min
                  </div>
                </div>
              </div>

              <div className="flex flex-col flex-start justify-around h-48">
                <div className="flex w-4/5 lg:w-auto m-auto min-h-fit-content">
                  <img
                    src="img/icones/i-bridge.png"
                    alt="A9 acesso"
                    className="h-5"
                  />
                  <div className="ml-4">
                    <span className="font-semibold">A9 acesso</span>
                    <br />5 km | 9 min
                  </div>
                </div>

                <div className="flex w-4/5 lg:w-auto m-auto min-h-fit-content">
                  <img
                    src="img/icones/i-market.png"
                    alt="supermercado"
                    className="h-6"
                  />
                  <div className="ml-4">
                    <span className="font-semibold">Supermercado</span>
                    <br />3 km | 5 min
                  </div>
                </div>
              </div>

              <div className="flex flex-col flex-start justify-around h-48">
                <div className="flex w-4/5 lg:w-auto m-auto min-h-fit-content">
                  <img
                    src="img/icones/i-bridge.png"
                    alt="A8 acesso"
                    className="h-5"
                  />
                  <div className="ml-4">
                    <span className="font-semibold">A8 acesso</span>
                    <br />3 km | 6 min
                  </div>
                </div>

                <div className="flex w-4/5 lg:w-auto m-auto min-h-fit-content">
                  <img
                    src="img/icones/i-hospital.png"
                    alt="hospital"
                    className="h-5"
                  />
                  <div className="ml-4">
                    <span className="font-semibold">Hospital</span>
                    <br />
                    3,5 km | 7 min
                  </div>
                </div>
              </div>

              <div className="flex flex-col flex-start justify-around h-48">
                <div className="flex w-4/5 lg:w-auto m-auto min-h-fit-content">
                  <img
                    src="img/icones/i-arrow.png"
                    alt="centro de loures"
                    className="h-5"
                  />
                  <div className="ml-4">
                    <span className="font-semibold">Loures (centro)</span>
                    <br />
                    3,5 km | 7 min
                  </div>
                </div>
                <div className="flex w-4/5 lg:w-auto m-auto min-h-fit-content">
                  <img
                    src="img/icones/i-arrow.png"
                    alt="lisboa"
                    className="h-6"
                  />
                  <div className="ml-4">
                    <span className="font-semibold">Lisboa (centro)</span>
                    <br />
                    18,5 km | 25 min
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Location;
