const NUMBER_IMAGES = 6;
const slider = [];
for (let i = 1; i <= NUMBER_IMAGES; i++) {
  slider.push({
    original: `img/home/home-${i}.jpg`,
    originalAlt: `Imagem ${i + 1} do apartamento`,
    thumbnail: `img/home/home-${i}.jpg`,
  });
}
export const HOME = {
  slider,
};
